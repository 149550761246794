























import { Component, Prop, Vue } from 'vue-property-decorator'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import { getCampaign } from '@/api/consoleApi/campaigns'
import HeatMap from '@/utils/heatmap'
import { getCampaignLinks } from '@/api/consoleApi/reports'

@Component({
    name: 'VsHeatmap',
    components: {
        VsContainer,
        VsFullTopBarLayout,
    },
})
export default class extends Vue {
    @Prop({ default: '', required: true }) campaignId!: any
    private loading = false
    private emailId = ''
    private mappedLinks: any[] = []
    private links: any[] = []
    $refs: any

    private async beforeMount () {
        this.loading = true
        await this.getCampaign()
    }

    private async iframeLoaded () {
        await this.initializeHeatmap()
        this.loading = false
    }

    async getCampaignLinks () {
        try {
            const links: any[] = []
            await this.loopCall(1, links, getCampaignLinks)
            return links
        } catch (e) {
            return []
        }
    }

    async loopCall (page: number, values: any, caller: any) {
        const resp = await caller(this.campaignId, {
            page,
            limit: 100,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values, caller)
        }
    }

    private async getCampaign () {
        try {
            const resp = await getCampaign(this.campaignId)
            const campaign = resp.data.data
            if (campaign.status !== 'Sent' || campaign.content_type !== 'email') this.$router.push({ name: 'campaignsIndex' })
            this.emailId = campaign.message.data.id
        } catch (e) {
            console.log(e)
            this.$router.push({ name: 'campaignsIndex' })
        }
    }

    private async initializeHeatmap () {
        this.mappedLinks = await this.getCampaignLinks()
        if (this.mappedLinks.length === 0) return

        const iframe = this.$refs.iframeComponent
        const heatmapInstance = new HeatMap({
            container: iframe.contentWindow.document.getElementsByTagName('body')[0],
            width: iframe.contentWindow.document.getElementsByTagName('body')[0].offsetWidth,
            height: iframe.contentWindow.document.getElementsByTagName('body')[0].offsetHeight,
        })
        const points = []
        this.links = iframe.contentWindow.document.getElementsByTagName('a')
        let max = 0
        let min = 0
        for (let i = 0; i < this.links.length; i++) {
            const found = this.mappedLinks.find((el: any) => el.link === this.links[i].getAttribute('data-href'))
            if (found) {
                let point = {
                    x: this.getOffset(this.links[i]).horizontal,
                    y: this.getOffset(this.links[i]).vertical,
                    value: found.total_click,
                }
                max = Math.max(max, found.total_click)
                min = Math.min(min, found.total_click)
                points.push(point)

                point = {
                    x: this.getOffset(this.links[i]).horizontal + this.getRandomCoords(),
                    y: this.getOffset(this.links[i]).vertical + this.getRandomCoords(),
                    value: found.total_click,
                }
                points.push(point)

                point = {
                    x: this.getOffset(this.links[i]).horizontal - this.getRandomCoords(),
                    y: this.getOffset(this.links[i]).vertical - this.getRandomCoords(),
                    value: found.total_click,
                }
                points.push(point)

                point = {
                    x: this.getOffset(this.links[i]).horizontal + this.getRandomCoords(),
                    y: this.getOffset(this.links[i]).vertical - this.getRandomCoords(),
                    value: found.total_click,
                }
                points.push(point)

                point = {
                    x: this.getOffset(this.links[i]).horizontal - this.getRandomCoords(),
                    y: this.getOffset(this.links[i]).vertical + this.getRandomCoords(),
                    value: found.total_click,
                }
                points.push(point)
            }
        }

        const data = {
            max,
            min,
            data: points,
        }
        heatmapInstance.setData(data)
    }

    private getOffset (el: any) {
        let _x = 0
        let _y = 0
        const _verticalOffset = el.offsetHeight / 2
        const _horizontalOffset = el.offsetWidth / 2
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft
            _y += el.offsetTop - el.scrollTop
            el = el.offsetParent
        }
        return { vertical: _y + _verticalOffset, horizontal: _x + _horizontalOffset }
    }

    private getRandomCoords () {
        return Math.floor(Math.random() * 30)
    }
}
